.header {
  background-color: #fffcfc;
  box-shadow: 0 2px 14px 0 rgba(15, 12, 49, 0.06);
  border-bottom: 1px solid #efeef6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  margin-left: 8rem;
  max-width: 300px;
  padding: 1rem 0;
}

.menu-icon {
  margin-right: 2rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #835F43;
  display: none;
}

.nav__list {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 10rem;
  font-size: 1.25rem;
}

.nav__list.mobile {
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  background-color: #fffcfc;
}

.nav__item {
  margin-left: 3rem;
}

.nav__link {
  color: #835F43;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: all 0.32s ease-in-out;
}

.nav__link:hover {
  background-color: #ffeeea;
  color: #7D5624;
  cursor: pointer;
  box-shadow: 0 2px 14px 0 rgba(32, 18, 182, 0.2);
}

/* responsive design */
@media (max-width: 768px) {
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  .hide-on-mobile {
    display: none;
  }

  .logo img {
    margin-left: 2rem;
    max-width: 150px;
  }

  .nav__list {
    display: none;
  }

  .nav__list.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 80px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.93);
    position: fixed;
    z-index: 999;
    padding-top: 42px;
    font-weight: 500;
  }

  .menu-icon {
    display: block;
  }

  .nav__item {
    margin-left: 0;
    margin-bottom: 1rem;
  }
}
