@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

body,
html {
  max-width: 100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

body {
  background-color: #ebe7e6;
  color: #764403;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-in-section {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-section.is-visible {
  opacity: 1;
}

.banner-wrapper {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.banner__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.banner__image {
  max-width: 100%;
  height: auto;
  padding-right: 15rem; /* Aumente o espaçamento */
  margin-top: 4rem;
}

.banner-text {
  order: 1;
  width: 40%;
  padding-left: 5rem; /* Adicione margem à esquerda se necessário */
  font-family: "Varela Round", sans-serif;
  color: #7d5624;
}

.button-style {
  background-color: #7d5624;
  color: #ffffff;
  font-weight: 700;
  border: 1px solid #444;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 1.3rem;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.button-style:hover {
  background-color: transparent;
  color: #7d5624;
  box-shadow: 0 2px 14px 0 rgba(32, 18, 182, 0.2);
}

.banner__image-container {
  order: 2;
  width: 50%;
}

.banner__image {
  max-width: 100%;
  height: auto;
  padding-right: 10rem;
  margin-top: 4rem;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 10%,
    black 90%,
    transparent 100%
  );
  mask-position: bottom;
  mask-repeat: no-repeat;
}

.banner-text h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.banner-text p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: justify;
}

/* Estilos para a seção "Procedimentos" */
#procedimentos {
  padding: 2rem;
  padding-left: 8rem;
  padding-right: 8rem;
  margin-top: 5rem;
  background-color: #fffcfc;
}

#procedimentos h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Varela Round", sans-serif;
  color: #7d5624;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  position: relative;
}

#procedimentos h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px; /* Aumentando o comprimento da linha */
  height: 2px;
  background-color: #7d5624;
}

#procedimentos h2::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #7d5624;
  border-radius: 50%;
}

.procedimento-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  flex-basis: calc(33.33% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.procedimentos__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 3rem;
}

.carousel-container {
  max-width: 500px;
  margin: 0 auto;
}

.slick-slide {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.procedimento-card .procedimento-image {
  width: 100%;
  height: auto;
}

.procedimento-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  flex-basis: calc(33.33% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-in-out;
}

.procedimento-card:hover {
  transform: scale(1.085);
}

.carousel-container .slick-next {
  font-size: 2rem;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #7d5624;
  color: #ffffff;
  margin-right: -2rem;
  color: transparent !important;
}

.carousel-container .slick-prev {
  font-size: 2rem;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #7d5624;
  color: #7d5624;
  margin-left: -2rem;
  color: transparent !important;
}

.carousel-container .slick-next:focus,
.carousel-container .slick-prev:focus {
  background-color: #7d5624 !important;
  outline: none;
}

.carousel-container .slick-next:not(:focus):hover,
.carousel-container .slick-prev:not(:focus):hover {
  background-color: #7d5624 !important;
  color: transparent !important;
  cursor: pointer;
  box-shadow: 0 2px 14px 0 rgba(32, 18, 182, 0.2);
}

/* Estilos para a seção "Atendimentos" */
#atendimentos {
  padding: 2rem;
  padding-left: 10rem;
  margin-top: 5rem;
  padding-bottom: 5rem;
  background-image: url(../../images/background.png);
  background-size: 12% auto;
  background-position: center;
  /* background-repeat: no-repeat; */
}

#atendimentos h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Varela Round", sans-serif;
  color: #7d5624;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
  position: relative;
}

#atendimentos h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px; /* Aumentando o comprimento da linha */
  height: 2px;
  background-color: #7d5624;
  text-align: center;
}

#atendimentos h2::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #7d5624;
  border-radius: 50%;
}

#atendimentos h2 {
  text-align: center;
  margin-top: -4rem;
}

/* Estilos para a seção "Sobre" */
/* Estilos para a seção "Sobre" */
#sobre {
  padding: 2rem;
  padding-top: 6rem;
  padding-left: 10rem;
  padding-right: 10rem;
  background-color: #fffcfc;
}

.sobre-wrapper {
  display: flex;
  align-items: center;
}

.sobre__image {
  margin-right: 2rem;
  mask-image: linear-gradient(
    to top,
    transparent,
    black 10%,
    black 90%,
    transparent 100%
  );
  mask-position: top;
  mask-repeat: no-repeat;
}

.sobre-text {
  flex-grow: 1;
  font-size: 1.2rem;
  margin-bottom: 15rem;
  text-align: justify;
  margin-right: 3rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 4rem;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 8rem;
}

.sobre-text h2 {
  padding-bottom: 1rem;
  text-align: center;
  font-size: 3rem;
  font-family: "Varela Round", sans-serif;
  color: #7d5624;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  position: relative;
}

.sobre-text h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px; /* Aumentando o comprimento da linha */
  height: 2px;
  background-color: #7d5624;
}

.sobre-text h2::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #7d5624;
  border-radius: 50%;
}

.sobre-text p {
  margin-bottom: 1.5rem;
}

.sobre-text strong {
  font-weight: bold;
}

.sobre-text em {
  font-style: italic;
}

/* Estilos para a seção "Contato" */
#contato {
  /* background-color: #ffeeea; */
  padding: 2rem;
  text-align: center;
}

#contato h2 {
  text-align: center;
  font-size: 3rem; /* Tamanho da fonte igual ao das outras seções */
  font-family: "Varela Round", sans-serif;
  color: #7d5624;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  position: relative;
}

#contato h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px; /* Aumentando o comprimento da linha */
  height: 2px;
  background-color: #7d5624;
}

#contato h2::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #7d5624;
  border-radius: 50%;
}

.contato__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Mantenha se quiser que os itens sejam movidos para a próxima linha em telas menores */
}

.contato__item {
  flex-basis: auto; /* Ajuste para 'auto' ou um valor menor */
  margin: 2.5rem; /* Ajuste as margens conforme necessário */
}

.contato__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem; /* Reduza o tamanho da fonte se necessário */
  color: #444;
  transition: color 0.3s ease-in-out;
}

.contato__item a:hover {
  color: #c06e7c;
}

.contato__item p {
  margin-top: 0.5rem; /* Ajuste o espaçamento do texto */
  font-size: 1.2rem;
}

/* Estilos para telas menores */
@media (max-width: 768px) {
  .banner__container {
    flex-direction: column;
    align-items: center;
  }

  .banner-text {
    order: 2;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  
  .banner-text p {
    font-size: 1.2rem;
    display: none;
  }

  .banner-text h1 {
    font-size: 1.17rem; /* Diminui o tamanho da fonte em telas menores */
    text-align: center;
    word-wrap: break-word; /* Quebra as palavras longas */
    margin: 1.3rem 1rem; /* Adiciona margens laterais para evitar que o texto encoste nas bordas */
  }
  
  .banner__image-container {
    order: 1;
    width: 100%;
    margin-bottom: 2rem;
  }

  .banner__image {
    padding-right: 0;
  }


  #procedimentos,
  #atendimentos {
    padding-left: 1rem; /* Reduzido para evitar cortes */
    padding-right: 1rem; /* Reduzido para evitar cortes */
  }

  .procedimentos__grid,
  .atendimentos__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .carousel-container {
    position: relative;
  }

  .carousel-container .slick-prev,
  .carousel-container .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .carousel-container .slick-prev {
    display: block !important;
    left: 10px;
  }

  .carousel-container .slick-next {
    right: 10px;
  }

  #sobre {
    padding-left: 1rem; /* Ajustado para evitar cortes */
    padding-right: 1rem; /* Ajustado para evitar cortes */
  }

  .sobre-text {
    margin: 0; /* Remove margens laterais */
    font-size: 0.9rem;
    text-align: justify; /* Justifica o texto */
    line-height: 1.5; /* Melhora a legibilidade */
  }

  .sobre-wrapper {
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo dentro do wrapper */
  }

  .sobre__image {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%; /* Garante que a imagem não ultrapasse as bordas */
    max-width: 400px; /* Limita a largura máxima da imagem se necessário */
  }

  .sobre-text h2 {
    margin-top: 0;
  }
}
