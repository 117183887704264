.footer {
  /* background-color: #fffcfc; */
  padding: 1rem;
  text-align: center;
}

.footer p {
  color: #7D5624;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer a {
  color: #7D5624;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer a:hover {
  color: #c06e7c;
}

/* Responsive design para o footer */
@media (max-width: 768px) {
  .footer {
    padding: 0.5rem;
  }

  .footer p {
    font-size: 1rem;
  }

  /* Adicione mais estilos se necessário */
}